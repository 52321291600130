import { useMutation, useQuery } from "@apollo/client";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Sidebar } from "../../components/sidebar/sidebar";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";
import { User } from "../../__generated__/graphql";
import { useEffect, useState } from "react";
import ProfileSkeleton from "../../components/skeleton/profileskeleton";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { MUTATION_UPDATE_USER, MUTATION_CHANGE_PASSWORD } from "../../graphql/mutations/user";
import 'react-phone-number-input/style.css';
import PhoneInputWithCountrySelect from "react-phone-number-input";
import PasswordField from "../../components/password-field/password-field";

export function ProfilePage() {
  const { data, loading, error } = useQuery<{ me: User }>(QUERY_GET_ME, { fetchPolicy: 'network-only' });

  const [updateUser, { data: updateData, loading: updateLoading }] = useMutation(MUTATION_UPDATE_USER, { fetchPolicy: 'network-only' });
  const [changePassword, { loading: passwordLoading }] = useMutation(MUTATION_CHANGE_PASSWORD);

  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [reEnterPassword, setReEnterPassword] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (data?.me) {
      setFirstName(data.me.firstName ?? undefined);
      setLastName(data.me.lastName ?? undefined);
      setEmail(data.me.email ?? undefined);
      setPhone(data.me.phone ?? undefined);
    }
  }, [data]);

  async function handleProfileSave(e: React.FormEvent) {
    e.preventDefault();
    setErrorMessage(null);

    try {
      await updateUser({
        variables: {
          firstName,
          lastName,
          phone
        }
      });
    } catch (error: any) {
      setErrorMessage("An error occurred: " + error.message);
    }
  }

  async function handleChangePassword(e: React.FormEvent) {
    e.preventDefault();
    setErrorMessage(null);

    if (newPassword && newPassword !== reEnterPassword) {
      setErrorMessage("New password and re-enter password do not match.");
      return;
    }

    if (!oldPassword || !newPassword || !reEnterPassword) {
      setErrorMessage("All password fields must be filled.");
      return;
    }

    try {
      await changePassword({
        variables: {
          oldPassword,
          newPassword
        }
      });

      window.location.reload();
    } catch (error: any) {
      if (error.message.includes("Incorrect old password")) {
        setErrorMessage("Old password is incorrect.");
      } else {
        setErrorMessage("An error occurred: " + error.message);
      }
    }
  }

  return (
    <>
      <GlobalErrorHandler />
      <Sidebar />
      <section className="flex flex-col space-y-8 content portfolio">
        <h1 className="mb-4 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">Profile</h1>
        {loading ? (
          <div className="flex flex-row justify-between w-full gap-8">
            <div className="w-1/2 card">
              <ProfileSkeleton />
            </div>
            <div className="w-1/2 card">
              <ProfileSkeleton />
            </div>
          </div>
        ) : (
          <>
            <div className="card">
              <h2 className="mb-6 text-2xl font-bold leading-normal text-deep-blue">Wallet Address</h2>
              <p className="bg-gray-100 px-4 py-2 rounded-lg w-auto">
                {data?.me.investorProfile?.solanaWalletAddress}
              </p>
            </div>            
            {data && data.me && (
              <div className="flex flex-row justify-between w-full gap-8">
                {/* Profile Update Card */}
                <form className="w-1/2 card" onSubmit={handleProfileSave}>
                  <div className="form-group">
                    <label>First name</label>
                    <input
                      autoFocus
                      value={firstName || ''}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter your first name"
                    />
                  </div>
                  <div className="mt-6 form-group">
                    <label>Last name</label>
                    <input
                      value={lastName || ''}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter your last name"
                    />
                  </div>
                  <div className="mt-6 form-group">
                    <label>Email address (cannot be changed)</label>
                    <input
                      type="email"
                      value={email || ''}
                      disabled
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="mt-6 form-group">
                    <label>Phone number</label>
                    <PhoneInputWithCountrySelect
                      defaultCountry="GB"
                      value={phone || ''}
                      onChange={setPhone}
                      placeholder="Enter your phone number"
                    />
                  </div>

                  <div className="mt-6 form-group">
                    <button
                      type="submit"
                      className="btn btn-lg"
                      style={{ height: 50, maxWidth: 200 }}
                      disabled={updateLoading}
                    >
                      Save Profile
                    </button>
                  </div>

                </form>

                {/* Password Change Card */}
                <form className="w-1/2 card" onSubmit={handleChangePassword}>
                  <h2 className="mb-6 text-2xl font-bold leading-normal text-deep-blue">
                    Change Password
                  </h2>
                  <div className="flex flex-col gap-2">
                    <label className='mb-2 -mt-2 font-semibold'>Old Password</label>
                    <PasswordField
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      placeholder='Enter your old password'
                      className="pr-16"
                    />
                  </div>

                  <div className="flex flex-col gap-2 mt-6">
                    <label className='mb-2 -mt-2 font-semibold'>New Password</label>
                    <PasswordField
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder='Enter your new password'
                    />
                  </div>
                  <div className="flex flex-col gap-2 mt-6">
                    <label className='mb-2 -mt-2 font-semibold'>Re-enter Password</label>
                    <PasswordField
                      value={reEnterPassword}
                      onChange={(e) => setReEnterPassword(e.target.value)}
                      placeholder='Re-enter your new password'
                    />
                  </div>

                  <div className="mt-12 form-group">
                    <button
                      type="submit"
                      className="btn btn-lg"
                      style={{ height: 50, maxWidth: 200 }}
                      disabled={passwordLoading}
                    >
                      Update
                    </button>
                  </div>

                  {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
                </form>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}
